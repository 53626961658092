import React from "react"
import { Button, Container, Paper } from "@material-ui/core/"

import { navigate } from "gatsby-link"
const Permission = () => {
  return (
    <Container component="main" maxWidth="xs" className="login-wrapper">
      <Paper className="paper">
        <h4>Oops! You don't have permission to view this page.</h4>
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/")
          }}
        >
          Back to Dashboard
        </Button>
      </Paper>
    </Container>
  )
}

export default Permission
